import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const BVWCLandingPage = () => (
  <PhysicianLandingPage
    physician="Brazos Valley Women's Center"
    institution="Brazos Valley Women's Center"
    referralCode="BVWC"
    physicianURL="https://www.bvwc.com/"
  />
)

export default BVWCLandingPage
